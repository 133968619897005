.login-container {
    display: flex;
    height: 100vh;
    overflow: hidden;

    .content-box {
        width: 40%;
        height: 100%;;
        background: #F2F2F2 0% 0% no-repeat padding-box;
        opacity: 1;

        .content {
            position: relative; 
            top: 30%;

            img {
                width: 372px;
                height: 144px;
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
            
            .title {
                position: relative; 
                top: 77px;
                text-align: center;
                display: flex;
                justify-content: center;
                p {
                    font: normal normal bold 43px/52px Helvetica, Arial, sans-serif;
                    letter-spacing: 0px;
                    text-transform: uppercase;
                    opacity: 1;
                    color: #00AAD7;

                }
                span {
                    font: normal normal bold 43px/52px Helvetica, Arial, sans-serif;
                    letter-spacing: 0px;
                    text-transform: uppercase;
                    opacity: 1;
                    color: #D72755;
                    padding-left: 10px;
                }
            }
        }
    }
    .form-container {
        width: 60%; 
        height: 100%;

        .form-content {
            position: relative; 
            top: 25%;

            .magic-token-box {
                text-align: center;
            }

            p {
                text-align: center;
                font: normal normal bold 40px/49px Helvetica, Arial, sans-serif;
                letter-spacing: 0px;
                opacity: 1;
                color: #183D4C;
            }

            .form {
                position: relative; 
                top: 0;

                .form-box {
                    justify-content: center;
                }
            }

            .input-title {
                padding: 8px;
                content: "\002A";
            }

            .submit-button {
                border: none;
                padding: 5px 10px;
                border-radius: 26px;
                text-transform: uppercase;
                background-color: #d73755;
                color: white;
                margin-top: 30px;
            }
        }
    }
}

@media (max-width: 1024px){
    .login-container {
        display: block;
        overflow: auto;

        .content-box {
            width: 100%;
            height: 30%;
            .content {
                position: relative; 
                top: 10%;
                img {
                    width: 179px;
                    height: 73px;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }
                .title {
                    top: 30px;
                }
                
            }
            
        }
        .form-container {
            width: 100%;
            height: 70%;;
            .form-content {
                top: 10%; 
            }
        }
    }
}

@media (max-width: 1600px){
    .login-container {
        .content-box {
            .content {
                .title {
                    display: block;
                }
            }    
        }
    }
}