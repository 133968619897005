.app {
    &Loading {
        // container
        width: 100%;
        height: 100vh;
    }
}

.fs-{
    &small {
        &-1 {
            font-size: 0.8em!important;
        }
    }
}

.btn- {
    &square-1 {
        min-width: 30px!important;
        width: 30px;
        height: 30px;
    }
}

.text- {
    &lowercase {
        text-transform: lowercase;
    }

    &uppercase {
        text-transform: uppercase;
    }
}

.border- {
    &none {
        border: none!important;
    }
}

.community-select-group-header {
    color: #999;
    font-weight: bold;
    background-color: #f0f0f0;
    font-size: 0.8em;
    padding: 3px 10px;
}
