
$grey: #f3f3f3;
$greyFocus: #e9e9e9;
$blueWaterColor: #0d9fdb;

.userapp-wever-input {
  position: relative;
  padding: 10px 10px;
  background-color: $grey;
  border: $grey;
  border-radius: 50px;

  .input-icon {
    // position: absolute;
    // left: 5px;
    font-size: 1.4em;
    margin-right: 5px;
    margin-left: 5px;
    color: $blueWaterColor;
  }

  input {
    background-color: $grey;
    border-color: $grey;
    letter-spacing: 2px;
  }

  input:focus {
    background-color: inherit;
    border-color: inherit;
    box-shadow: none;
  }

  input::placeholder {
    color: black;
  }
}

.userapp-wever-input:hover,
.userapp-wever-input:hover > input {
  background-color: $greyFocus;
  border-color: $greyFocus;
}