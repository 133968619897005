@import './color.scss';
@import './utils.scss';
@import './components/views/single-graph.scss';
@import './components/views/descriptionTab.scss';
@import './components/views/upload.scss';
@import './components/views/login-page.scss';
@import './components/views/project-members.scss';
@import './components/views/collector.scss';
@import './components/security/input.scss';

.profile-title{
    font-size: 27px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    }
    .profile-text-parameter{
    font-size: 16px;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    }

    .validationIconBorder{
        border-radius: 50%;    
        border: 1px  solid;
        width: 27px;
        height: 27px;
        min-width: 27px;
        min-height: 27px;
        text-align: center;
        
    
    }
    .validationIcon{
        font-size: 15px;
        margin-right: 0%; 
    }   
    .validationDescription{
        margin-left: 2%; 
    }

    .description-tentative-password{
        font-size: 15px;
        letter-spacing: 0px;
        color: #000000;
        padding-top: 5%;
    }

    .input-group {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
    }

    .input-group-text {
        display: flex;
        align-items: center;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        text-align: center;
        white-space: nowrap;
        background-color: #e9ecef;
        border: 1px solid #ced4da;
        border-radius: 0.375rem;
    }

    .rectangle-blue {
        background-color: #f0f6fe!important;
        opacity: 1;
        border: none!important;
    }

    .btn-wever {
        text-transform: uppercase;
        background-color: #d73755 !important;
        border-radius: 10px;
        color: #fff!important;
        padding: 5px 30px!important;
        box-shadow: none;
        font-weight: 700!important;
    }

    .user-security-text {
        display: none;
    }