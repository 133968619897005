.modale-wever {
    z-index: 1000;
    position: absolute;
    top: 20%;
    left: 40%;
    padding: 10px;
    width: 30%;
    min-width: 300px;
    border: 1px solid #ccc;
    background-color: white;;
}

.modal-wever-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}