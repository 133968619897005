.collector-one-page {
  & button.bg-wever-red {
    background-color: $darkPink !important;
  }

  .question-responses-container {
    margin-left: 1.5em;
    input.radio,
    input.checkbox {
      margin-left: -1.5em !important;
      margin-right: 1em !important;
      align-self: flex-start !important;

      &.next {
        margin-left: 1.5em !important;
      }
    }

    label:has(.radio) {
      margin-left: 1em;
    }
  }
}
