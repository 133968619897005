.description-tab {
  .header {
    display: flex;
    justify-content: space-between;
    padding-top: 5%;

    .categories {
      display: flex;
      align-items: center;

      span {
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        font-weight: 400;
        color: #d73755;
      }

      .category {
        margin-left: 10px;
        background-color: #d73755;
        border-radius: 10px 10px 10px 10px;
        padding: 5px 10px 5px 10px;

        span {
          font-family: 'Roboto', sans-serif;
          font-size: 18px;
          font-weight: 400;
          color: #f2f2f2;
        }
      }
    }

    .accept-button {
      cursor: pointer;
      margin-left: 10px;
      background-color: #d73755;
      border-radius: 25px 25px 25px 25px;
      padding: 5px 12px 5px 12px;

      span {
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: 400;
        color: #f2f2f2;
      }
    }
  }

  .content {
    h3 {
      padding-top: 20px;
    }
    h3 > span {
      font-family: Helvetica;
      font-weight: bold;
      color: #00aad7;
      line-height: 30px;
      padding-top: 30px;
      font-size: 24px;

      span {
        font-family: Helvetica;
        font-weight: 200;
      }
    }

    ul li {
      font-family: Helvetica;
      font-weight: 400;
    }

    .presentation-box {
      display: flex;
      padding-top: 5%;
      padding-bottom: 15px;

      img {
        height: auto;
        width: auto;
        max-width: 500px;
      }

      .presentation {
        padding-left: 30px;

        h3 {
          padding-top: 0px;
        }

        .objectives {
          padding-top: 30px;
        }
      }
      .no-image {
        padding-left: 0px;
      }
    }
  }
}
