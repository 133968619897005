.upload-container {
    border: 2px #d3dee7 dashed;
    border-radius: 10px;
    height: 300px;

    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 10%;
        padding-top: 50px;
        cursor: pointer;
    }

    .content {
        text-align: center;
        line-height: 18px;
        p {
            font-size: 21px;
            font-family: 'Roboto', sans-serif;
            text-align: center;
        }
        
        span {
            font-size: 16px;
            font-family: 'Roboto', sans-serif;
            text-align: center;
            color: #7f7ba8;
        }
    }
    
}